<template>
  <div class="myrefund">
    <van-nav-bar
      :title="$t('MYREFUND')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div v-if="empty" class="empty">
      <van-empty
        class="custom-image"
        :image="emptyImg"
      >
      <div slot="description" >
        <div class="statuttop">{{$t('NEEDNOTBORRORW')}}</div>
        <div class="statutbottom">{{$t('CURRENTMONTHNEEDNOTBORRORW')}}</div>
      </div>
      </van-empty>
    </div>
    <van-cell-group v-else>
      <van-cell>
        <div class="title" slot="title">
            {{$t('BORRORSERIALBUNBER')}}:
          </div>
          <div class="showstatus" slot="default">{{huankuan.status}}</div>
          <div slot="label">{{huankuan.id}}</div>
          <!-- <van-icon color="#666" size="20" slot="right-icon" name="arrow" /> -->
      </van-cell>
      <van-cell>
        <div slot="icon" class="imgbox">
          <van-image round class="iconimg" :src="imgurlBB" />
        </div>
        <div class="title" slot="title">
          {{$t('BORRORWMONEY')}}:{{huankuan.money}}Rp
        </div>
        <div class="fon16" slot="label">
          <div class="borrowTime">{{$t('EVERYISSUE')}}:{{huankuan.monthly}}Rp</div>
          <div class="everyBorrow">{{$t('BORRORWMONEYTIME')}}:{{huankuan.months}}{{$t('MONTHS')}}</div>
        </div>
      </van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import { repaymentList } from '@/api/my'
import './css/my.css'
export default {
  name: 'myrefund',
  data () {
    return {
      empty: false,
      emptyImg: require('./img/p_01 (1).png'),
      imgurlBB: require('./img/money.jpg'),
      huankuan: {}
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    },
    async getrepaymentList () {
      try {
        const { data } = await repaymentList()
        if (data.code === 200) {
          // console.log(data)
          this.huankuan = data.data
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  created () {
    this.getrepaymentList()
  }
}
</script>

<style scoped>
.imgbox {
  margin-right: 10px;
  box-sizing: border-box;
  padding: 5px;
  width: 60px;
  height: 60px;
  border: 1px solid #e4e4e4;
}
.iconimg {
  width: 100%;
  height: 100%;
}
.custom-image .van-empty__image {
  width: 90px;
  height: 90px;
}
.statuttop,
.statutbottom {
  padding: 5px 0;
  text-align: center;
  font-size: 15px;
}
</style>
